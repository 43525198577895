import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main id="main">
      <div className="page__header-container page__header-container--four-o-four-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header four-o-four-landing__header page__header--four-o-four-landing"
            id="listing-top"
          >
            <div className="col-12 col-lg-8">
              <h1>Sorry, we couldn't find that page.</h1>
              <a href="/" className="btn btn-primary button-primary-hero">
                Go back
              </a>
            </div>
            <div className="stphilips-spacing"></div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default NotFoundPage
